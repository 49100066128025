<template>
  <div class="overview container">
    <h1>Overview</h1>
    <p>Stats ...</p>

  </div>
</template>

<script>
export default { name: 'BackendOverview' };
</script>
